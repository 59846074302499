import React from 'react'
import clsx from 'clsx'
import { useField } from 'formik'

/* styling */
import styles from './text.module.css'

interface Props {
  label?: string
  name: string
  className?: string
  inputClass?: string
  placeholder?: string
  labelClass?: string | undefined
  type?: 'text' | 'number' | 'password'
  required?: boolean
}

const TextField = ({
  label,
  name,
  className,
  placeholder,
  inputClass,
  type = 'text',
  labelClass,
  required
}: Props) => {
  const [field, meta] = useField<string | undefined>(name)

  return (
    <div className={clsx('input__container', className)}>
      {label && (
        <label
          className={clsx(
            labelClass,
            'block  | text-gray-80 font-medium  |  mb-2'
          )}
          style={{ fontSize: 13 }}
          htmlFor={`form-${name}`}
        >
          <span>{label}</span>
          {required && <span className="text-orange-50">&nbsp;*</span>}
        </label>
      )}
      <input
        {...field}
        id={`form-${name}`}
        placeholder={placeholder}
        type={type}
        className={clsx(
          'border border-gray-40 rounded-lg  |  w-full h-12  |  px-4',
          inputClass,
          styles.input,
          (key: string) => styles[`input_${key}`]
        )}
        required={required}
      />

      {meta.touched && meta.error ? (
        <div className="text-small font-medium text-orange-50  |  mt-2">
          {meta.error}
        </div>
      ) : null}
    </div>
  )
}

export default TextField
